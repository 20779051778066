export const GLOBAL_CONFIGS = {
    // Header for rapidAPI access
    HEADER: {
        FORM_URL_ENCODED: { 'Content-Type': 'application/x-www-form-urlencoded' },
        MULTIPART_FORM_DATA: { 'Content-Type': 'multipart/form-data' },
    },
    // Request
    METHOD: {
        POST: 'POST',
        GET: 'GET',
        PUT: 'PUT',
        DELETE: 'DELETE',
    },
    // Endpoints to API
    ENDPOINTS: {
        // DEV
        BASE_URL: `${process.env.REACT_APP_API_URL}`,

        // WS for request
        LOGIN: '/users/login',
        USERS: '/users',
        CLIENTS: '/clients',
        MESSAGES_POST: '/messages/send',
        MESSAGES_GET: '/messages',
        PRODUCTS: '/products',
    },
    TOKEN: 'token',
    MESSAGE: 'message',
    ITEMPERPAGE: 10,
};
