import { Layout, Row, Button, Form, Col } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;
const { Item } = Form;
interface ICustomHeaderProps {
    margin?: any;
}

export const CustomHeader = styled(Header)`
    background-color: #fff;
    margin: ${(props: ICustomHeaderProps) => props.margin};
    &.ant-layout-header {
        padding: 0px;
    }
`;

export const RowStyled = styled(Row)`
    paddingbottom: 50px;
    margintop: 50px;
`;

// Styled Listing Component
export const RowButtonActionStyled = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonActionStyled = styled(Button)`
    margin: ${(props: ICustomHeaderProps) => props.margin};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const ColSiderDashboardEmpty = styled(Col)`
    border: #ccc solid 1px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    min-height: 60vh;
`;

export const ColSiderDashboardWithData = styled(Col)`
    border: #ccc solid 1px;
    border-radius: 10px;
    margin-top: 25px;
    min-height: 60vh;
`;

// Styled HeaderActionComponent
export const RowHeaderActionStyled = styled(Row)`
    margin-top: 25px;
`;

export const FormItemStyled = styled(Item)`
    margin: 0px 10px;
    display: flex;
    justify-content: flex-end;
`;

export const ColHeaderActionStyled = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;

// Styled DashboardComponent
export const RowDashboardComponent = styled(Row)`
    margin-top: 25px;
`;

// Styled SiderComponent
export const DivEmptyStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FormSiderMessageStyled = styled(Form)`
    padding: 10px;
    margin-top: 25px;
`;
