import { Row, Button } from 'antd';
import styled from 'styled-components';

interface ICustomHeaderProps {
	margin?: any;
}

// Styled Listing Message
export const RowButtonMessageStyled = styled(Row)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ButtonActionMessageStyled = styled(Button)`
	margin: ${(props: ICustomHeaderProps) => props.margin};
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
