import { IClient } from '@/interfaces/InterfaceData';
import { MessageOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Checkbox, PaginationProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { uniqBy } from 'lodash';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { colorTag } from '../../utils/colorsTools';

interface IListingClientComponentProps {
    isLoading?: boolean;
    listClients?: any;
    showModalMessage: (value?: any) => void;
    getSelectedRow: (data: IClient[]) => void;
    selectedRowKeys: React.Key[];
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
    isShowModal: boolean;
    setIsMinimized: React.Dispatch<React.SetStateAction<boolean>>;
}

const ListingClientComponent = forwardRef((props: IListingClientComponentProps, ref) => {
    const { listClients, showModalMessage, getSelectedRow, selectedRowKeys, setSelectedRowKeys, isShowModal, setIsMinimized, isLoading } = props;
    const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0);


    // Hooks useful to reset selection between onChange Tabs
    useImperativeHandle(ref, () => ({
        resetRowsKey() {
            setSelectedRowKeys([]);
        },
    }));

    // Used by rowSelection
    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: IClient[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
        getSelectedRow(selectedRows);
        setSelectedRowsCount(selectedRows.length);
    };

    // Disable Button Message if rows selectionned > 1
    const checkSelectedRowsCount = () => selectedRowsCount > 1;

    const handleCheckAlldata = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked
        const selectedClient = uniqBy(listClients?.rows, 'id') || []
        const newSelectedRowKeys = selectedClient.map((i: any) => i.id)
        !isChecked ? onSelectChange([], [] as any) : onSelectChange(newSelectedRowKeys, selectedClient as any)
    }

    const headerCheckbox = (
        <Checkbox
            checked={!!selectedRowKeys.length}
            onChange={handleCheckAlldata}
        />
    );

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnTitle: headerCheckbox
    };

    // Header Client table with data mapping data by dataIndex
    const columnsHeaderClients = [
        {
            title: 'Nom',
            dataIndex: 'firstName',
        },
        {
            title: 'Prénoms',
            dataIndex: 'lastName',
        },
        {
            title: 'Numéro CIN',
            dataIndex: 'cin',
        },
        {
            title: 'Numéro téléphone',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Produit',
            dataIndex: 'products',
            render: (product: any) => (
                <Tag key={product.id} color={colorTag(product.name)}>
                    {product.name}
                </Tag>
            ),
        },
        // Comment until date become useful from server
        // {
        //     title: "Date d'échéance",
        //     dataIndex: 'expiryDate',
        //     render: (date: any) => {
        //         return checkDateIsNull(date, 'Aucune échéance');
        //     },
        // },
        // {
        //     title: 'Date de paiement',
        //     dataIndex: 'paymentDate',
        //     render: (date: any) => {
        //         return checkDateIsNull(date, 'Attente de paiement');
        //     },
        // },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => (
                <Button
                    type="primary"
                    disabled={checkSelectedRowsCount()}
                    onClick={() => {
                        if (!isShowModal) showModalMessage();
                        setIsMinimized(false);
                        getSelectedRow([record]);
                        setSelectedRowKeys([record.id]);
                    }}
                >
                    <MessageOutlined />
                    Message
                </Button>
            ),
        },
    ];

    // Used to custom data message in case empty table
    const localeTable = {
        emptyText: isLoading ? 'Recherche en cours...' : 'Aucun résultat',
    };

    // Used to custom pagination text
    const localePagination = {
        jump_to: 'Aller vers',
    };

    // Custom Render Pagination button
    const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
        if (type === 'prev') {
            return <>Page précédente</>;
        }
        if (type === 'next') {
            return <>Page suivante</>;
        }
        return originalElement;
    };

    return (
        <Table
            rowKey="id"
            rowClassName="pointer-cursor"
            dataSource={(uniqBy(listClients?.rows, 'id') || []) as any}
            locale={localeTable}
            loading={isLoading}
            columns={columnsHeaderClients}
            rowSelection={rowSelection}
            pagination={{
                locale: localePagination,
                position: ['bottomCenter'],
                showQuickJumper: true,
                total: listClients?.count,
                defaultPageSize: 20,
                itemRender,
            }}
        />
    );
});

export default ListingClientComponent;
