import React from 'react';
import Header from '@/common/Layout/Header';
import DashboardComponent from '../../components/Dashboard/DashboardComponent';
import { ContentDashboardStyled } from './styled';

const DashboardScreen = () => {
	return (
		<>
			<Header />
			<ContentDashboardStyled>
				<DashboardComponent />
			</ContentDashboardStyled>
		</>
	);
};

export default DashboardScreen;
