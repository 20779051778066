import { Col } from 'antd';
import * as React from 'react';
import TextComponent from '@/common/TextComponent';
import { MinusOutlined, CloseOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import { HeaderRowStyled, RowStyled } from './styled';
import localStorageTools from '../../../utils/localStorageTools';
import { GLOBAL_CONFIGS } from '../../../configs/configs';

interface IModalMessageComponentProps {
    isMinimized: boolean;
    handleMinimizedModal: () => void;
    handleMaximizedModal: () => void;
    handleShowModal: () => void;
}

export const HeaderRowComponent: any = (props: IModalMessageComponentProps) => {
    const { handleMinimizedModal, handleShowModal, handleMaximizedModal, isMinimized } = props;

    const deleteMessageDraft = () => {
        // Delete localstorage draft
        localStorageTools.deleteValueFromLocalStorage(GLOBAL_CONFIGS.MESSAGE);
    };

    // Style used by Icons
    const styleIcon = { marginRight: '10px' };

    return (
        <HeaderRowStyled>
            <Col span={8}>
                <TextComponent text="Message" level={3} />
            </Col>
            <Col span={8} offset={8}>
                <RowStyled>
                    {isMinimized && <ArrowsAltOutlined style={styleIcon} onClick={() => handleMinimizedModal()} />}
                    {!isMinimized && <MinusOutlined style={styleIcon} onClick={() => handleMinimizedModal()} />}
                    <CloseOutlined
                        onClick={() => {
                            handleMaximizedModal();
                            handleShowModal();
                            deleteMessageDraft();
                        }}
                    />
                </RowStyled>
            </Col>
        </HeaderRowStyled>
    );
};
