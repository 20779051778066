/* eslint-disable jsx-a11y/anchor-is-valid */
import logo_apem_paiq from '@/assets/logos/logo-apem-paiq.png';
import ImageComponent from '@/common/ImageComponent/ImageComponent';
import SpinnerLoader from '@/common/Loader/SpinnerLoader/index';
import TextComponent from '@/common/TextComponent/TextComponent';
import { Button, Form, Input } from 'antd';
import 'antd/dist/antd.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import openNotification from '../../common/Notification/index';
import { SignIn } from '../../services/auth';
import { COLORS } from '../../utils/colorsTools';
import {
    ColStyled,
    DivStyled,
    FormStyled,
    ItemStyled,
    SpaceStyled,
} from './styled';

const Login: React.FC<any> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const onFinish = (values: any) => {
        setLoading(true);
        SignIn(values).then(
            (response: any) => {
                setLoading(false);
                if (response === 'Error') {
                    openNotification({
                        title: 'Erreur',
                        description: 'Erreur login...',
                    });
                } else {
                    navigate('/outbox');
                }
            },
            (error) => {
                setLoading(false);
                const isErrorUndefined = error === undefined;
                openNotification({
                    title: 'Erreur',
                    description: isErrorUndefined
                        ? 'Erreur lors de la connexion au serveur...'
                        : error?.message,
                });
            },
        );
    };

    const onFinishFailed = () => {
        openNotification({
            title: 'Erreur',
            description: 'Veuillez vérifier vos identifiants...',
        });
    };

    if (loading) {
        return <SpinnerLoader />;
    }

    return (
        <DivStyled>
            <FormStyled
                name="form-login"
                initialValues={{ remember: true, email: '', password: '' }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item>
                    <SpaceStyled direction="horizontal">
                        <ColStyled>
                            <ImageComponent image={logo_apem_paiq} width={97} />
                            <TextComponent
                                text="SMS Push Text"
                                level={2}
                                color={COLORS.BLUE}
                            />
                            <TextComponent text="Connexion" level={3} />
                        </ColStyled>
                    </SpaceStyled>
                </Form.Item>
                <ItemStyled>
                    <Form.Item
                        name={['email']}
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez entrer un identifiant ',
                            },
                        ]}
                    >
                        <Input placeholder="Identifiant" />
                    </Form.Item>
                    <Form.Item
                        name={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez entrer un mot de passe',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Mot de passe" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block={true}>
                            Connexion
                        </Button>
                    </Form.Item>
                </ItemStyled>
            </FormStyled>
        </DivStyled>
    );
};

export default Login;
