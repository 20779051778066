import { Button, Col, Form, Space } from 'antd';
import styled from 'styled-components';

const { Item } = Form;

export const FormLoginForgotStyled = styled.a`
  float: right;
`;
export const SpaceStyled = styled(Space)`
  width: 100%;
  justify-content: center;
  padding: 25px 50px;
`;

export const LoginButtonStyled = styled(Button)`
  height: 40px;
  width: 280px;
  left: 820px;
  top: 618px;
  border-radius: 2px;
  padding: 6px 15px 6px 15px;
`;
export const DivStyled = styled.div`
  padding: 30px;
`;

export const FormStyled = styled(Form)`
  background-color: #fff;
  border: #ccc solid 1px;
  height: 533px;
  width: 484px;
  left: 718px;
  top: 202px;
  border-radius: 12px;
`;

export const ColStyled = styled(Col)`
  &.ant-col {
    text-align: center;
    justify-content: center;
  }
`;
export const ItemStyled = styled(Item)`
  padding: 0px 100px;
`;
