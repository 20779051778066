import axios from 'axios';
import { checkTokenUser } from '@/services/auth';
import { GLOBAL_CONFIGS } from '../configs/configs';

// Axios Get Method
const axiosGet = (endpoint: string) =>
    axios({
        method: GLOBAL_CONFIGS.METHOD.GET,
        url: GLOBAL_CONFIGS.ENDPOINTS.BASE_URL + endpoint,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${checkTokenUser()}`,
        },
    });

const axiosPostFormUrlEncoded = (params: any, endpoint: string) =>
    axios({
        method: GLOBAL_CONFIGS.METHOD.POST,
        url: `${GLOBAL_CONFIGS.ENDPOINTS.BASE_URL}${endpoint}`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${checkTokenUser()}`,
        },
        data: params,
    });

const axiosPostRaw = (params: any, endpoint: string) =>
    axios({
        method: GLOBAL_CONFIGS.METHOD.POST,
        url: `${GLOBAL_CONFIGS.ENDPOINTS.BASE_URL}${endpoint}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${checkTokenUser()}`,
        },
        data: params,
    });

// Export All Methods Axios
const WS = {
    axiosGet,
    axiosPostFormUrlEncoded,
    axiosPostRaw,
};

export default WS;
