import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const ContentStyled = styled(Content)`
  display: flex;
  justify-content: center;
  height: 100vh;
  background: #ececec;
  align-items: center;
`;
