import { Image } from 'antd';
import React from 'react';

interface IImageComponentProps {
  image: any;
  width: number;
  height?: number;
  isPreview?: boolean;
}

const ImageComponent = ({
  image,
  width,
  height = width,
  isPreview = false,
}: IImageComponentProps) => <Image width={width} src={image} height={height} preview={isPreview} />;

export default ImageComponent;
