import { GLOBAL_CONFIGS } from '../configs/configs';
import WS from './api';

export const productsGet = () => {
  return new Promise((resolve, reject) => {
    WS.axiosGet(`${GLOBAL_CONFIGS.ENDPOINTS.PRODUCTS}`).then(
      (response) => {
        resolve(response?.data);
      },
      (error) => {
        reject(error?.response?.data);
      }
    );
  });
};
