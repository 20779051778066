import SpinnerLoader from '@/common/Loader/SpinnerLoader/index';
import openNotification from '@/common/Notification';
import TextComponent from '@/common/TextComponent';
import TEXT from '@/constant';
import { messagesGet } from '@/services/messages';
import { productsGet } from '@/services/products';
import { Col, Empty, Tabs } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IClient, IMessage, IProduct, IUrlSearchClientParams } from '../../interfaces/InterfaceData';
import { clientsGet } from '../../services/users';
import { urlSearchParams } from '../../utils/urlSearchParams';
import ListingClientComponent from '../ListingComponent/ListingClient';
import ListingMessageComponent from '../ListingComponent/ListingMessage';
import ModalMessageComponent from './CustomModal/ModalMessage';
import HeaderActionDashboard from './HeaderDashboard/HeaderActionDashboard';
import HeaderFiltreDashboard from './HeaderDashboard/HeaderFiltreDashboard';
import SiderMessage from './SiderMessage';
import { ColSiderDashboardEmpty, ColSiderDashboardWithData, RowDashboardComponent } from './styled';

interface IMessageProps {
    rows: IMessage[];
    count: number;
}
interface IClientProps {
    rows: IClient[];
    count: number;
}

const DashboardComponent = () => {
    const navigate = useNavigate();
    const [clients, setClients] = useState<IClientProps | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedOneOrMoreRow, setSelectedOneOrMoreRow] = useState<IClient[]>([]);
    const [selectedOneOrMoreRowCount, setSelectedOneOrMoreRowCount] = useState<number>(0);
    const [selectedRowDisplay, setSelectedRowDisplay] = useState<IMessage>();
    const [products, setProducts] = useState<IProduct[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isMinimized, setIsMinimized] = useState<boolean>(false);
    const [isOutbox, setIsOutbox] = useState<boolean>(true);
    const [messages, setMessages] = useState<IMessageProps | null>(null);
    const styleCol = { padding: '0px 15px' };
    const resetRowsRef = useRef<any>();
    const [loading, setLoading] = useState(false);
    const [activeTabs, setActiveTabs] = useState<string>('outbox');
    const [listDataToDisplay, setListDataToDisplay] = useState<any>();

    const closeModal = () => setLoading(false);
    // CallBack to get update list clients
    const filterClients = (data: IUrlSearchClientParams) => {
        setLoading(true);
        if (isOutbox) {
            clientsGet(urlSearchParams(data))
                .then((response: any) => {
                    setClients(response);
                    if (!isEmpty(response)) {
                        closeModal();
                    }
                })
                .catch((err) => {
                    openNotification({ title: 'Erreur', description: err });
                    closeModal();
                });
        } else {
            messagesGet(urlSearchParams(data))
                .then((response: any) => {
                    setMessages(response);
                    if (!isEmpty(response)) {
                        closeModal();
                    }
                })
                .catch((err) => {
                    openNotification({ title: 'Erreur', description: err });
                    closeModal();
                });
        }
    };
    // Callback Get the selectedRow from ListingComponent
    const getSelectedRow = (data: IClient[]) => {
        setSelectedOneOrMoreRow(data);
        setSelectedOneOrMoreRowCount(data.length);
    };

    // Callback Get the selectedRow to display sms from ListingMessage
    const getSelectedRowDisplay = (data: any) => {
        setSelectedRowDisplay(data);
    };

    // CallBack to get update modal status
    const showModalMessage = () => {
        setShowModal(!showModal);
        setIsMinimized(false);
    };

    // Handle Modal Minimized status
    const handleMinimizedModal = () => {
        setIsMinimized(!isMinimized);
    };

    // Handle Modal Minimized status
    const handleMaximizedModal = () => {
        setIsMinimized(!isMinimized);
    };

    // Destroy checkbox selectionned rows
    const resetRows = () => resetRowsRef.current.resetRowsKey();

    // Handle navigation url in switching tab
    const OnChangeTabs = (key: string) => {
        navigate(`/${key}`);
        setActiveTabs(key);
        setSelectedOneOrMoreRowCount(0);
        setSelectedOneOrMoreRow([]);
        setIsOutbox(!isOutbox);
        setShowModal(false);
        resetRows();
    };

    // Get Clients, Messages, Products once
    useEffect(() => {
        productsGet()
            .then((response: any) => {
                setProducts(response);
            })
            .catch((err) => {
                openNotification({ title: 'Erreur', description: err });
            });
    }, []);

    // Update list Clients | Messages in case using filter
    useEffect(() => {
        setClients(clients);
        setMessages(messages);
    }, [clients, messages]);

    // Set the list for ModalMessage
    useEffect(() => (isOutbox ? setListDataToDisplay(clients?.rows) : setListDataToDisplay(messages?.rows)), [isOutbox, clients, messages]);

    return (
        <>
            <Tabs
                destroyInactiveTabPane={true}
                onChange={(key: string) => OnChangeTabs(key)}
                items={[
                    {
                        label: "Boite d'envoi",
                        key: 'outbox',
                        children: (
                            <>
                                <HeaderFiltreDashboard filterClients={(data: any) => filterClients(data)} products={products} isMessageSent={false} />
                                <HeaderActionDashboard
                                    isListMessages={false}
                                    clients={clients}
                                    selectedRowCount={selectedOneOrMoreRowCount}
                                    selectedOneOrMoreRowCount={selectedOneOrMoreRowCount}
                                    showModalMessage={() => showModalMessage()}
                                />

                                <ListingClientComponent
                                    {...{
                                        setIsMinimized,
                                        selectedRowKeys,
                                        setSelectedRowKeys,
                                    }}
                                    isLoading={loading}
                                    ref={resetRowsRef}
                                    isShowModal={showModal}
                                    listClients={clients}
                                    showModalMessage={() => showModalMessage()}
                                    getSelectedRow={(data: IClient[]) => getSelectedRow(data)}
                                />
                            </>
                        ),
                    },
                    {
                        label: 'SMS envoyés',
                        key: 'sendbox',
                        children: (
                            <>
                                <div>
                                    <HeaderFiltreDashboard filterClients={(data: any) => filterClients(data)} products={products} isMessageSent={true} />
                                </div>
                                <RowDashboardComponent>
                                    <Col span={16} style={styleCol}>
                                        <HeaderActionDashboard
                                            isListMessages={true}
                                            messages={messages}
                                            selectedRowCount={selectedOneOrMoreRowCount}
                                            selectedOneOrMoreRow={selectedOneOrMoreRow}
                                            selectedOneOrMoreRowCount={selectedOneOrMoreRowCount}
                                            showModalMessage={() => showModalMessage()}
                                        />
                                        <ListingMessageComponent
                                            {...{
                                                setIsMinimized,
                                                selectedRowKeys,
                                                setSelectedRowKeys,
                                            }}
                                            isLoading={loading}
                                            ref={resetRowsRef}
                                            listMessages={messages}
                                            showModalMessage={() => showModalMessage()}
                                            getSelectedRow={(data: IClient[]) => getSelectedRow(data)}
                                            getSelectedRowDisplay={(data: IClient) => getSelectedRowDisplay(data)}
                                        />
                                    </Col>
                                    {selectedRowDisplay ? (
                                        <ColSiderDashboardWithData span={8} style={styleCol}>
                                            <SiderMessage selectedRowDisplay={selectedRowDisplay} />
                                        </ColSiderDashboardWithData>
                                    ) : (
                                        <ColSiderDashboardEmpty span={8}>
                                            <Empty
                                                description={
                                                    <>
                                                        <TextComponent text={TEXT.emptySelectedSMSTitle} />
                                                        <p>{TEXT.emptySelectedSMSDescription}</p>
                                                    </>
                                                }
                                            />
                                        </ColSiderDashboardEmpty>
                                    )}
                                </RowDashboardComponent>
                            </>
                        ),
                    },
                ]}
            />
            {showModal && (
                <ModalMessageComponent
                    {...{ setSelectedRowKeys }}
                    listClients={listDataToDisplay}
                    isMinimized={isMinimized}
                    activeTabs={activeTabs}
                    oneOrMoreSelectedUser={selectedOneOrMoreRow}
                    setSelectedOneOrMoreRow={setSelectedOneOrMoreRow}
                    handleShowModal={() => showModalMessage()}
                    handleMinimizedModal={() => handleMinimizedModal()}
                    handleMaximizedModal={() => handleMaximizedModal()}
                />
            )}
        </>
    );
};

export default DashboardComponent;
