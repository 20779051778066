import { Alert, Form, Modal, Row } from 'antd';
import styled from 'styled-components';

const { Item } = Form;

export const ModalMessageStyled = styled(Form)`
    position: fixed;
    width: 572px;
    bottom: 0;
    right: 15px;
    background-color: #fff;
    padding: 0px 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 1000;
`;

export const FormItemStyled = styled(Item)`
    float: right;
`;

export const ModalValidationStyled = styled(Modal)`
    &.ant-alert .ant-alert-warning .ant-alert-banner {
        background-color: #fff !important;
    }
`;

export const AlertStyled = styled(Alert)`
    &.ant-alert-warning {
        background-color: #fff;
    }
`;

export const RowStyled = styled(Row)`
  float: right;
  padding 10px;
`;

export const HeaderRowStyled = styled(Row)`
  padding 10px;
`;
