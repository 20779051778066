import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from '../components/Login/Login';
import LoginScreen from '../views/Account/index';
import DashboardScreen from '../views/Dashboard/index';
import { PrivateRoute } from './privateRoute';
import routes from './routes';

const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={routes.ROOT || routes.LOGIN} element={<LoginScreen />} />
				<Route path={routes.LOGIN} element={<Login />} />
				<Route
					path={routes.OUTBOX}
					element={
						<PrivateRoute>
							<DashboardScreen />
						</PrivateRoute>
					}
				/>
				<Route
					path={routes.SENDBOX}
					element={
						<PrivateRoute>
							<DashboardScreen />
						</PrivateRoute>
					}
				/>{' '}
				<Route path='*' element={<Navigate to={routes.ROOT} />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
