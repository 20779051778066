import React from 'react';
import { Button, ButtonProps } from 'antd';

interface IButton {
    htmlType?: 'button' | 'submit' | 'reset';
    type?: 'link' | 'text' | 'dashed' | 'default' | 'primary' | 'ghost';
    actions?: () => void;
    child?: React.ReactNode;
}

export const CustomButton: React.FC<IButton & ButtonProps> = (props) => {
    const { actions, htmlType = 'button', type = 'primary', child } = props;
    return (
        <Button  {...props} type={type} htmlType={htmlType} onClick={actions}>
            {child}
        </Button>
    );
};
