import { ISendMessage } from '../interfaces/InterfaceData';

export const isObjectOrArrayNull = (array: any) => {
    return Object.keys(array).length === 0;
};

// Format data structure
export const formatUserDataForSend = (data: any) => {
    const tabs: ISendMessage[] = [];
    data?.forEach((client: any) => {
        const Client = client.client ?? client;
        tabs.push({
            messageId: Client.id,
            messages: Client.content,
            contact: Client.phoneNumber,
            firstName: Client.firstName,
            lastName: Client.lastName,
            cin: Client.cin,
            clientId: Client.clientId,
        });
    });

    return tabs;
};
