import React from 'react';
import { TitleStyled } from './styled';

interface ITitleProps {
  text: string;
  level?: any;
  color?: string;
  style?: any;
}

const TextComponent = ({ text, level = 5, color, style }: ITitleProps) => {
  return (
    <TitleStyled level={level} color={color} style={style}>
      {text}
    </TitleStyled>
  );
};

export default TextComponent;
