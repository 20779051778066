import { Modal } from 'antd';

interface IAlertModalProps {
    title?: string;
    content?: string;
    okText?: string;
    cancelText?: string;
    okCancel?: boolean;
    onOk: () => void;
}

export const openErrorModal = ({
    title = 'Message Non envoyé',
    content = 'Une erreur est survenu...',
    okText = 'Renvoyer message',
    cancelText = 'Annuler',
    onOk,
}: IAlertModalProps) => {
    Modal.error({
        title,
        content,
        cancelText,
        okCancel: true,
        okText,
        onOk: () => onOk(),
    });
};

export const openWarningModal = ({
    title = 'Envoi de message',
    content = 'Etes-vous sûr de vouloir envoyer ce message ?',
    okText = 'Envoyer',
    cancelText = 'Annuler',
    onOk,
}: IAlertModalProps) => {
    Modal.warning({
        title,
        content,
        cancelText,
        okCancel: true,
        okText,
        onOk: () => onOk(),
    });
};

export const openSuccessModal = ({
    title = 'Message envoyé',
    content = 'Votre message a bien été envoyé',
    okText = 'Fermer',
    onOk,
    cancelText = 'Annuler',
    okCancel = false,
}: IAlertModalProps) => {
    Modal.success({
        title,
        content,
        cancelText,
        okCancel,
        okText,
        onOk: () => onOk(),
    });
};
