import { CustomButton } from '@/common/Buttons/CustomButton';
import CustomSelect from '@/common/CustomSelect';
import SpinnerLoader from '@/common/Loader/SpinnerLoader/index';
import { TextAreaComponent } from '@/common/TextComponent/TextAreaComponent';
import { HeaderRowComponent } from '@/components/Dashboard/CustomModal/ModalMessageComponent';
import { FormItemStyled, ModalMessageStyled } from '@/components/Dashboard/CustomModal/styled';
import { IClient, IMessage, ISendMessage } from '@/interfaces/InterfaceData';
import { sendMessage } from '@/services/messages';
import { CheckOutlined } from '@ant-design/icons';
import { Form, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import _, { uniqBy } from 'lodash';
import CONSTANTS from '../../../constant';
import localStorageTools from '../../../utils/localStorageTools';
import { GLOBAL_CONFIGS } from '../../../configs/configs';
import { openErrorModal, openWarningModal, openSuccessModal } from '../../../common/AlertModal/AlertModal';
import { formatUserDataForSend } from '../../../utils/arrayOrObjectTools';

interface ICustomModalProps {
    activeTabs?: string;
    isMinimized?: boolean;
    listClients: any;
    oneOrMoreSelectedUser?: any;
    handleShowModal: () => void;
    handleMinimizedModal: () => void;
    handleMaximizedModal: () => void;
    setSelectedOneOrMoreRow: React.Dispatch<React.SetStateAction<IClient[]>>;
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
}

const ModalMessageComponent: React.FC<ICustomModalProps> = (props: ICustomModalProps) => {
    const {
        handleShowModal,
        handleMinimizedModal,
        handleMaximizedModal,
        listClients,
        isMinimized,
        activeTabs,
        oneOrMoreSelectedUser,
        setSelectedOneOrMoreRow,
        setSelectedRowKeys,
    } = props;

    const [loading, setLoading] = useState(false);
    const [messageData, setMessageData] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [isSendForAll, setIsSendForAll] = useState<boolean>(false);
    const { Text } = Typography;

    useEffect(() => {
        listClients && setIsSendForAll(oneOrMoreSelectedUser?.length === listClients.length);
    }, [oneOrMoreSelectedUser, listClients]);

    const onChangeTextArea = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target) setMessageData(e.target.value);
        localStorageTools.saveValueToLocalStorage(GLOBAL_CONFIGS.MESSAGE, e.target.value);
    };

    // Callback to get data from select component
    const getUsersFromSelect = (data: any[]) => {
        // Userdata for Outbox
        const userData = listClients?.filter((client: any) => data.find((item) => item.value === client.id));
        const userDataSelected = data?.map((item) => item.value);

        // Userdata for Sendbox
        const userDataWithClientId = listClients?.filter((client: any) => data.find((item) => item.title === client.clientId));
        const selectedRowKeysWithClientId = userDataWithClientId?.map((item: any) => item.id);

        // Check which userdata will be displayed according to tabs
        const selectedRowKeys = activeTabs === CONSTANTS.tabs.SEND_BOX ? selectedRowKeysWithClientId : userDataSelected;

        // Set data by parent callbacks
        setSelectedOneOrMoreRow(userData);
        setSelectedRowKeys(selectedRowKeys);
    };

    // Send data to api for sending SMS
    const messageServicePost = () => {
        let isErrorSms = false;
        const oneOrMoreSelectedUserFormatted = formatUserDataForSend(oneOrMoreSelectedUser);

        sendMessage(null, uniqBy(oneOrMoreSelectedUserFormatted, 'contact'), messageData).then((response: any) => {
            setLoading(false);
            if (response.status === 500) {
                isErrorSms = true;
            }
        });

        // Delete localstorage draft
        localStorageTools.deleteValueFromLocalStorage(GLOBAL_CONFIGS.MESSAGE);

        isErrorSms &&
            openErrorModal({
                onOk: messageServicePost,
            });

        !isErrorSms &&
            openSuccessModal({
                onOk: handleShowModal,
            });
    };

    // Function submit Form in case failed
    const onFinishFailed = () => {
        setIsError(_.isEmpty(oneOrMoreSelectedUser));
    };

    // Check error select
    const onFinish = () => {
        !_.isEmpty(oneOrMoreSelectedUser)
            ? openWarningModal({
                  onOk: () => messageServicePost(),
              })
            : onFinishFailed();
    };

    if (loading) {
        return <SpinnerLoader />;
    }

    const handleClickAllSelection = () => {
        setIsSendForAll(!isSendForAll);
        setSelectedOneOrMoreRow(!isSendForAll ? listClients : []);
        setSelectedRowKeys(!isSendForAll ? listClients.map((item: IClient) => item.id) : []);
    };

    // Check Message Draft as default value in Textarea
    const checkDefaultValue = () => localStorageTools.getValueFromLocalStorage(GLOBAL_CONFIGS.MESSAGE) || '';

    // Hide the button in Sendbox when userSelected <= 1

    const checkDisableButton = (oneOrMoreSelectedUser?.length > 1 && activeTabs === CONSTANTS.tabs.SEND_BOX) || activeTabs === CONSTANTS.tabs.OUT_BOX;

    return (
        <ModalMessageStyled name="form-modal-message" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
            <Form.Item>
                <HeaderRowComponent
                    isMinimized={isMinimized}
                    handleMinimizedModal={() => handleMinimizedModal()}
                    handleMaximizedModal={() => handleMaximizedModal()}
                    handleShowModal={() => handleShowModal()}
                />
            </Form.Item>
            {!isMinimized && (
                <>
                    {checkDisableButton && (
                        <Form.Item>
                            <CustomButton
                                child={
                                    <>
                                        <CheckOutlined />
                                        {!isSendForAll ? CONSTANTS.selectAll : CONSTANTS.unselectAll}
                                    </>
                                }
                                actions={handleClickAllSelection}
                            />
                        </Form.Item>
                    )}
                    <Form.Item>
                        <CustomSelect
                            listClients={uniqBy(listClients, 'clientId')}
                            Selectvalue={uniqBy(oneOrMoreSelectedUser, 'clientId')?.map((client: any) => {
                                return {
                                    label: `${client.firstName} ${client.lastName}`,
                                    value: client.id,
                                };
                            })}
                            getUsersFromSelect={(data: any[]) => getUsersFromSelect(data)}
                        />
                        {isError && _.isEmpty(oneOrMoreSelectedUser) && <Text type="danger"> {CONSTANTS.addTextDestinataire} </Text>}
                    </Form.Item>
                    <Form.Item name="message" rules={[{ required: true, message: CONSTANTS.addNewMessage }]}>
                        <TextAreaComponent onChange={(e) => onChangeTextArea(e)} defaultValue={checkDefaultValue()} />
                    </Form.Item>
                    <FormItemStyled>
                        <CustomButton htmlType="submit" child={CONSTANTS.sendMessage} />
                    </FormItemStyled>
                </>
            )}
        </ModalMessageStyled>
    );
};

export default ModalMessageComponent;
