// Save the value to localStorage
const saveValueToLocalStorage = <T = any>(key: string, data: T) => {
  if (typeof data === 'string') {
    localStorage.setItem(key, data);
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

// Return value from locageStorage
const getValueFromLocalStorage = <T = any>(key: string) => {
  const rawData = localStorage.getItem(key);

  if (!rawData) {
    return null;
  }

  try {
    return JSON.parse(rawData) as T;
  } catch (error) {
    return rawData;
  }
};

// Return value from locageStorage
const deleteValueFromLocalStorage = (key: string) => {
  try {
    return localStorage.removeItem(key);
  } catch (error) {
    return null;
  }
};

const localStorageTools = {
  saveValueToLocalStorage,
  getValueFromLocalStorage,
  deleteValueFromLocalStorage,
};

export default localStorageTools;
