export const COLORS = {
	BLUE: '#03237A',
	PRIMARY: '#1890FF',
	YELLOW: '#FFC700',
	GREY_WHITE: '#F0F0F0',
	RED: '#FFEBEB',
};

// Set color for tag Product
export const colorTag = (text: string) => {
	switch (text) {
		case 'Maitso':
			return 'green';
		case 'Roso':
			return 'red';
		case 'Vonjy':
			return 'blue';
		default: {
			break;
		}
	}
};

// Set color for tag Product
export const colorTagStatus = (status: boolean) => {
	switch (status) {
		case false:
			return 'red';
		case true:
			return 'green';
		default: {
			break;
		}
	}
};
