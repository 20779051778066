import TextComponent from '@/common/TextComponent/TextComponent';
import { SearchOutlined } from '@ant-design/icons';
import { Col, ConfigProvider, DatePicker, Form, Input, Row, Select } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { IProduct } from '../../../interfaces/InterfaceData';
import { CustomHeader } from '../styled';

const { RangePicker }: any = DatePicker;

interface IHeaderFiltreDashboardProps {
    filterClients: (data: any) => void;
    products: IProduct[];
    isMessageSent: boolean;
}

const HeaderFiltreDashboard = (props: IHeaderFiltreDashboardProps) => {
    const { filterClients, products, isMessageSent } = props;

    const [search, setSearch] = useState<string>('');
    const [productId, setProductId] = useState<any>('');
    const [status, setStatus] = useState<any>('');
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const statusData = [
        {
            id: 'true',
            name: 'Envoyé',
        },
        {
            id: 'false',
            name: 'Non envoyé',
        },
    ];

    const { Option } = Select;

    // Style for each column
    const styleCol = { padding: '0px 10px' };

    // Style for Row identifiant
    const styleRow = {
        display: 'flex',
        justifyContent: 'start',
        margin: '0px',
    };

    // Option Product
    const onProductorStatusChange = (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (isMessageSent) {
            setStatus(value);
        } else {
            setProductId(value);
        }
    };

    // Handle value Input search and call callback functions from DashboardComponent
    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target) {
            setSearch(e.target.value);
        }
    };

    // Debounce for onChangeSearch
    const debouncedChangeHandler = debounce((e) => handleChangeSearch(e), 500);

    // Handlle change date picker
    const onChangeDate = (value: any, dateString: any) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    useEffect(() => {
        filterClients({ search, productId, startDate, endDate, status });
        // This line below remove strict dependency of useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, productId, startDate, endDate, status]);

    const listingProduct = (product: IProduct) => {
        return (
            <Option key={product.id} value={product.id}>
                {product.name}
            </Option>
        );
    };

    // // Switch text placeholder to display
    // const getPlaceholder = () => (isMessageSent ? 'Tous les statuts' : 'Tous les produits');

    return (
        <CustomHeader>
            <TextComponent text="Filtre recherche" level={3} style={{ marginBottom: '15px' }} />

            <Row style={styleRow}>
                <Col span={isMessageSent ? 12 : 18} style={styleCol}>
                    <Form.Item name="identifiant">
                        <Input placeholder="Nom, Prénoms, CIN, Téléphone" prefix={<SearchOutlined />} onChange={debouncedChangeHandler} allowClear={true} />
                    </Form.Item>
                </Col>
                {isMessageSent && (
                    <Col span={6} style={styleCol}>
                        <Form.Item name="date">
                            <RangePicker placeholder={['Date début', 'Date fin']} value="date" format="YYYY-MM-DD" onChange={onChangeDate} />
                        </Form.Item>
                    </Col>
                )}
                {!isMessageSent && (
                    <Col span={6} style={styleCol}>
                        <Form.Item name="offres">
                            <ConfigProvider renderEmpty={() => 'Aucun résultat'}>
                                <Select placeholder="Tous les produits" onChange={onProductorStatusChange} allowClear={true}>
                                    {products?.map((data: any) => listingProduct(data))}
                                </Select>
                            </ConfigProvider>
                        </Form.Item>
                    </Col>
                )}
                {isMessageSent && (
                    <Col span={6} style={styleCol}>
                        <Form.Item name="offres">
                            <ConfigProvider renderEmpty={() => 'Aucun résultat'}>
                                <Select placeholder="Tous les statuts" onChange={onProductorStatusChange} allowClear={true}>
                                    {isMessageSent && statusData?.map((data: any) => listingProduct(data))}
                                    {!isMessageSent && products?.map((data: any) => listingProduct(data))}
                                </Select>
                            </ConfigProvider>
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </CustomHeader>
    );
};

export default HeaderFiltreDashboard;
