import { Typography } from 'antd';
import styled from 'styled-components';

interface ITypographyComponent {
  color?: string;
  fontSize?: string;
  margins?: any;
  fontFamily?: string;
}

const { Title } = Typography;

export const TitleStyled = styled(Title)`
  font-size: ${(props: ITypographyComponent) => (props.fontSize ? props.fontSize : '16px')};
  &.ant-typography {
    font-family: ${(props: ITypographyComponent) =>
      props.fontFamily ? props.fontFamily : 'PublicSansVariable'};
    color: ${(props: ITypographyComponent) => (props.color ? props.color : 'black')};
    margin: ${(props: ITypographyComponent) => (props.margins ? props.margins : '0px')};
  }
`;
