import { Select } from 'antd';
import React from 'react';

interface ICustomSelectProps {
    placeholder?: string;
    listClients?: any;
    getUsersFromSelect: (data: string[]) => void;
    Selectvalue?: any[];
}

const CustomSelect = (props: ICustomSelectProps) => {
    const { placeholder = 'Destinataires', getUsersFromSelect, listClients, Selectvalue } = props;

    // Handle user list state
    const handleChange = (value: any[]) => {
        getUsersFromSelect(value);
    };

    return (
        <Select
            mode="multiple"
            labelInValue={true}
            style={{ width: '100%' }}
            placeholder={placeholder}
            onChange={handleChange}
            optionLabelProp="label"
            allowClear={true}
            value={Selectvalue}
            notFoundContent="Aucun résultat"
            autoClearSearchValue={true}
            options={listClients.map((item: any) => {
                return {
                    label: `${item.firstName} ${item.lastName}`,
                    value: item.id,
                    title: item.clientId,
                };
            })}
        />
    );
};

export default CustomSelect;
