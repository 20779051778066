import { Layout } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;

export const CustomHeader = styled(Header)`
  background-color: #fff;
  &.ant-layout-header {
    padding: 0px;
  }
`;

export const CustomSpan = styled.span`
  lineheight: 48px;
  fontweight: bold;
  fontsize: medium;
`;
export const CustomSpanUser = styled.span`
  lineheight: 48px;
  float: right;
  color: rgba(255, 255, 255, 0.65);
`;
