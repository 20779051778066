import { GLOBAL_CONFIGS } from '../configs/configs';
import WS from './api';
import { ISendMessage } from '../interfaces/InterfaceData';

export const sendMessage = (messageId: string | null, userData: ISendMessage[], messages: string | null) => {
    const formData = {
        to: userData.map((item) => {
            if (messages) return { ...item, messages, messageId };
            return item;
        }),
    };

    return new Promise((resolve, reject) => {
        WS.axiosPostRaw(formData, `${GLOBAL_CONFIGS.ENDPOINTS.MESSAGES_POST}`).then(
            (response) => {
                resolve(response.data);
            },
            (error) => {
                reject(error.response.data);
            },
        );
    });
};

export const messagesGet = (filterData?: any) => {
    let query = '';
    if (filterData) {
        query = `?${filterData}`;
    }

    return new Promise((resolve, reject) => {
        WS.axiosGet(`${GLOBAL_CONFIGS.ENDPOINTS.MESSAGES_GET}${query}`).then(
            (response) => {
                resolve(response?.data);
            },
            (error) => {
                reject(error?.response?.data);
            },
        );
    });
};
