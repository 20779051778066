export default {
    selectAll: 'Sélectionner tout',
    unselectAll: 'Desélectionner tout',
    addTextDestinataire: 'Veuillez saisir une destinataire',
    addNewMessage: 'Veuillez saisir le message !',
    sendMessage: 'Envoyer message',
    emptySelectedSMSDescription: 'Cliquer sur une ligne dans le tableau pour afficher le contenu du SMS.',
    emptySelectedSMSTitle: 'Aperçu de message',
    tabs: {
        SEND_BOX: 'sendbox',
        OUT_BOX: 'outbox',
    },
};
