import resend from '@/assets/icones/resend.svg';
import SpinnerLoader from '@/common/Loader/SpinnerLoader/index';
import { IClient, ISendMessage } from '@/interfaces/InterfaceData';
import { colorTagStatus } from '@/utils/colorsTools';
import { MessageOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Checkbox, PaginationProps, Space, Table, Tag } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { uniqBy } from 'lodash';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { IconComponent } from '../../common/Icones/IconeComponent';
import { GLOBAL_CONFIGS } from '../../configs/configs';
import { sendMessage } from '../../services/messages';
import { checkDateIsNull } from '../../utils/dateTools';
import { ButtonActionMessageStyled } from './styled';

interface IListingMessageComponentProps {
    isLoading?: boolean;
    listMessages?: any;
    showModalMessage: () => void;
    getSelectedRow: (data: IClient[]) => void;
    getSelectedRowDisplay: (data: IClient) => void;
    selectedRowKeys: React.Key[];
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
}

const ListingMessageComponent = forwardRef((props: IListingMessageComponentProps, ref) => {
    const { listMessages, showModalMessage, getSelectedRow, getSelectedRowDisplay, selectedRowKeys, setSelectedRowKeys, isLoading } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        resetRowsKey() {
            setSelectedRowKeys([]);
        },
    }));

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: IClient[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
        getSelectedRow(selectedRows);
        setSelectedRowsCount(selectedRows.length);
    };

    // Disable Button Message if rows selectionned > 1
    const checkSelectedRowsCount = () => selectedRowsCount > 1;

    const handleCheckAlldata = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked
        const selectedMessages = uniqBy(listMessages?.rows, 'id') || []
        const newSelectedRowKeys = selectedMessages.map((i: any) => i.id)
        !isChecked ? onSelectChange([], [] as any) : onSelectChange(newSelectedRowKeys, selectedMessages as any)
    }

    const headerCheckbox = (
        <Checkbox
            checked={!!selectedRowKeys.length}
            onChange={handleCheckAlldata}
        />
    );

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnTitle: headerCheckbox
    };

    // Set the text by status true or false
    const setStatusText = (status: boolean) => {
        switch (status) {
            case false:
                return 'Non envoyé';
            case true:
                return 'Envoyé';
            default: {
                break;
            }
        }
    };

    // Post message in case message status failed
    const resendMessagePost = (client: any) => {
        const userData: ISendMessage[] = [
            {
                id: client.id,
                contact: client.phoneNumber,
                firstName: client.firstName,
                lastName: client.lastName,
                cin: client.cin,
            },
        ];

        sendMessage(client.id, userData, client.content).then(() => {
            setLoading(false);
        });
    };

    const columnsHeaderMessages = [
        {
            title: 'Nom',
            dataIndex: 'firstName',
        },
        {
            title: 'Prénoms',
            dataIndex: 'lastName',
        },
        {
            title: 'Numéro CIN',
            dataIndex: 'cin',
        },
        {
            title: 'Numéro téléphone',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            render: (status: any) => (
                <Tag key={status} color={colorTagStatus(status)}>
                    {setStatusText(status)}
                </Tag>
            ),
        },
        {
            title: "Date d'envoi",
            dataIndex: 'createdAt',
            render: (date: any) => {
                return checkDateIsNull(date, 'Aucune date');
            },
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'status',
            render: (status: any, client: any) => (
                <Space size="middle">
                    <ButtonActionMessageStyled
                        type="primary"
                        disabled={checkSelectedRowsCount()}
                        onClick={() => {
                            onSelectChange([client.id], [client])
                            getSelectedRow([client]);
                            showModalMessage();
                        }}
                    >
                        <MessageOutlined />
                    </ButtonActionMessageStyled>
                    <ButtonActionMessageStyled
                        type="primary"
                        disabled={!status && checkSelectedRowsCount() && true}
                        onClick={() => {
                            setLoading(true);
                            resendMessagePost(client);
                        }}
                    >
                        <Icon component={() => IconComponent(resend)} />
                    </ButtonActionMessageStyled>
                </Space>
            ),
        },
    ];

    // Used to custom data message in case empty table
    const locale = {
        emptyText: isLoading ? 'Recherche en cours...' : 'Aucun résultat',
    };

    // Used to custom pagination text
    const localePagination = {
        jump_to: 'Aller vers',
    };

    if (loading) {
        return <SpinnerLoader />;
    }

    // Pagination table data
    const onChangePagination: PaginationProps['onChange'] = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Custom Render Pagination button
    const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
        if (type === 'prev') {
            return <>Page précédente</>;
        }
        if (type === 'next') {
            return <>Page suivante</>;
        }
        return originalElement;
    };

    return (
        <Table
            rowKey="id"
            rowClassName="pointer-cursor"
            dataSource={listMessages?.rows || []}
            locale={locale}
            columns={columnsHeaderMessages}
            rowSelection={rowSelection}
            loading={isLoading}
            pagination={{
                position: ['bottomCenter'],
                locale: localePagination,
                showQuickJumper: true,
                defaultCurrent: 1,
                current: currentPage,
                total: listMessages?.count,
                pageSize: GLOBAL_CONFIGS.ITEMPERPAGE, // Count data to display per page
                itemRender,
                onChange: (key, value) => onChangePagination(key, value),
            }}
            onRow={(record) => {
                return {
                    onClick: () => getSelectedRowDisplay(record),
                };
            }}
        />
    );
});

export default ListingMessageComponent;
