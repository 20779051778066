import * as React from 'react';
import { Form, Divider } from 'antd';
import TextComponent from '@/common/TextComponent';
import { FormSiderMessageStyled } from './styled';
import { convertDateToDDMMYY } from '../../utils/dateTools';

interface ISiderMessage {
    selectedRowDisplay: any;
}

const SiderMessage = (props: ISiderMessage) => {
    const { selectedRowDisplay } = props;

    const isCinNotNull = selectedRowDisplay?.cin ? `/${selectedRowDisplay?.cin}` : '';

    return (
        <FormSiderMessageStyled name="form-sider-message">
            <Form.Item label="À">
                <TextComponent text={`${selectedRowDisplay?.firstName} ${selectedRowDisplay?.lastName}  ${isCinNotNull}`} />
            </Form.Item>
            <Form.Item label="Le">{convertDateToDDMMYY(selectedRowDisplay?.updatedAt)}</Form.Item>

            <Divider />

            <Form.Item>{selectedRowDisplay?.content}</Form.Item>
        </FormSiderMessageStyled>
    );
};

export default SiderMessage;
