import { notification } from 'antd';

interface INotificationProps {
    title?: string;
    description?: string;
}

const openNotification = ({
    title = 'Erreur',
    description = 'Erreur',
}: INotificationProps) => {
    notification.error({
        message: title,
        description,
    });
};

export default openNotification;
