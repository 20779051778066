/* eslint-disable react/forbid-prop-types */
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo_apem_paiq from '@/assets/logos/logo-apem-paiq.png';
import ImageComponent from '@/common/ImageComponent/ImageComponent';
import { COLORS } from '../../../utils/colorsTools';
import localStorageTools from '../../../utils/localStorageTools';
import TextComponent from '../../TextComponent/index';
import { CustomHeader } from './styled';

const Header = () => {
    const navigate = useNavigate();

    return (
        <CustomHeader>
            <Row
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <Col
                    lg={1}
                    xs={24}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <ImageComponent image={logo_apem_paiq} width={55} />
                </Col>
                <Col lg={4} xs={24} style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                    <TextComponent text="SMS Push Text" level={2} color={COLORS.BLUE} />
                </Col>{' '}
                <Col
                    lg={8}
                    xs={24}
                    offset={10}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <Button
                        onClick={() => {
                            localStorageTools.deleteValueFromLocalStorage('token');
                            navigate('/');
                        }}
                    >
                        Se déconnecter
                    </Button>
                </Col>
            </Row>
        </CustomHeader>
    );
};

export default Header;
