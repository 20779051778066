/* eslint-disable jsx-a11y/anchor-is-valid */
import Login from '@/components/Login';
import 'antd/dist/antd.css';
import React from 'react';
import { ContentStyled } from './styled';

const Account: React.FC = () => {
	return (
		<ContentStyled>
			<Login />
		</ContentStyled>
	);
};

export default Account;
