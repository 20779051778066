import { GLOBAL_CONFIGS } from '../configs/configs';
import WS from './api';

export const clientsGet = (filterData?: any) => {
    let query: any = '';
    if (filterData) {
        query = `?${filterData}`;
    }

    return new Promise((resolve, reject) => {
        WS.axiosGet(`${GLOBAL_CONFIGS.ENDPOINTS.CLIENTS}${query}`).then(
            (response) => {
                resolve(response?.data?.data);
            },
            (error) => {
                reject(error?.response?.data);
            },
        );
    });
};
