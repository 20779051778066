import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

interface ITextAreaProps {
    rows?: number;
    defaultValue?: string;
    onChange: (data: any) => void;
}

export const TextAreaComponent = (props: ITextAreaProps) => {
    const { rows = 7, onChange, defaultValue = '' } = props;
    return <TextArea rows={rows} onChange={onChange} defaultValue={defaultValue} />;
};
