import { Spin } from 'antd';
import React, { FC } from 'react';

const SpinnerLoader: FC = () => {
  return (
    <Spin
      style={{ top: '50%', left: '50%', position: 'absolute' }}
      size="large"
      tip="Veuillez patienter..."
    />
  );
};

export default SpinnerLoader;
