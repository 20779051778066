import { GLOBAL_CONFIGS } from '../configs/configs';
import localStorageTools from '../utils/localStorageTools';
import WS from './api';

// Service Signin
export const SignIn = (data: any) => {
	const formData = new URLSearchParams();

	formData.append('email', data.email);
	formData.append('password', data.password);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormUrlEncoded(
			formData,
			`${GLOBAL_CONFIGS.ENDPOINTS.LOGIN}`
		).then(
			(response) => {
				if (response?.data)
					localStorageTools.saveValueToLocalStorage(
						GLOBAL_CONFIGS.TOKEN,
						response?.data?.token
					);
				resolve(response?.data);
			},
			(error) => {
				reject(error?.response?.data);
			}
		);
	});
};

export const checkTokenUser = () => {
	return localStorageTools.getValueFromLocalStorage(GLOBAL_CONFIGS.TOKEN);
};

export const signOut = () => {
	return localStorageTools.deleteValueFromLocalStorage(GLOBAL_CONFIGS.TOKEN);
};
