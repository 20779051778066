import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkTokenUser } from '../services/auth';

interface IPrivateRoute {
  children: React.ReactNode;
}
export const PrivateRoute = (props: IPrivateRoute) => {
  const { children } = props;
  const isAuthenticated = checkTokenUser();
  // If not connected redirect to root
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <div>{children}</div>;
};
