import { MessageOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space } from 'antd';
import React, { useState } from 'react';
import TextComponent from '@/common/TextComponent/TextComponent';
import Icon from '@ant-design/icons/lib/components/Icon';
import resend from '@/assets/icones/resend.svg';
import SpinnerLoader from '@/common/Loader/SpinnerLoader/index';
import { COLORS } from '../../../utils/colorsTools';
import { RowHeaderActionStyled, FormItemStyled, ColHeaderActionStyled } from '../styled';
import { IconComponent } from '../../../common/Icones/IconeComponent';
import { ButtonResendStyled } from './styled';
import { sendMessage } from '../../../services/messages';
import { IClient, IProduct, IMessage } from '../../../interfaces/InterfaceData';
import { openSuccessModal } from '../../../common/AlertModal/AlertModal';
import { formatUserDataForSend } from '../../../utils/arrayOrObjectTools';

interface IHeaderActionDashboardProps {
    isListMessages: boolean;
    messages?: any;
    clients?: any;
    selectedRowCount: number;
    showModalMessage: () => void;
    selectedOneOrMoreRowCount: number;
    selectedOneOrMoreRow?: IClient[] | IMessage[] | IProduct[];
}

const HeaderActionDashboard = (props: IHeaderActionDashboardProps) => {
    const {
        isListMessages,
        showModalMessage,
        selectedRowCount = 0,
        selectedOneOrMoreRowCount = 0,
        selectedOneOrMoreRow = [],
        messages = [],
        clients = [],
    } = props;
    const [loading, setLoading] = useState(false);
    // Enable Actions Buttons group if more 2 rows selectionned
    const checkSelectedRow = () => selectedOneOrMoreRowCount <= 1;

    // Check if none rows selectionned
    const isNoneRowSelectionned = () => selectedOneOrMoreRowCount !== 0;

    // Check messages count
    const isMessageArrayNull = () => messages && messages.count === 0;

    // Check client count
    const isClientsArrayNull = () => clients && clients.count === 0;

    // Re post  multiple message
    const resendMessagePost = () => {
        try {
            setLoading(true);
            const userDataWithMesageId = formatUserDataForSend(selectedOneOrMoreRow);
            sendMessage(null, userDataWithMesageId, null).then(() => {
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    };

    const onSubmit = () => {
        openSuccessModal({
            title: 'Renvoi de message',
            content: 'Etes-vous sûr de vouloir renvoyer le(s) message ?',
            okText: 'Renvoyer',
            okCancel: true,
            onOk: () => resendMessagePost(),
        });
    };

    if (loading) {
        return <SpinnerLoader />;
    }

    // Check if messages or clients not null or selectionned rows not null
    const isDisabled = () => isNoneRowSelectionned() || (isListMessages && isMessageArrayNull()) || (!isListMessages && isClientsArrayNull());

    return (
        <div>
            <Form name="form-dashboard" autoComplete="off">
                <RowHeaderActionStyled>
                    <Col xs={24} sm={12} md={6} lg={4}>
                        <Form.Item name="new-message">
                            <Button
                                type="ghost"
                                htmlType="button"
                                disabled={isDisabled()}
                                onClick={() => {
                                    showModalMessage();
                                }}
                                style={{
                                    backgroundColor: isDisabled() ? COLORS.GREY_WHITE : COLORS.YELLOW,
                                    color: isDisabled() ? 'grey' : 'white',
                                }}
                            >
                                <PlusSquareOutlined />
                                Nouveau message
                            </Button>
                        </Form.Item>
                    </Col>

                    <ColHeaderActionStyled xs={24} sm={12} md={16} lg={20}>
                        <Row>
                            <FormItemStyled name="actionsGroup">
                                <Space size="middle">
                                    <TextComponent text={`${selectedRowCount} Sélectionné(s)`} level={5} style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                                    <TextComponent text="Actions groupés: " level={5} />
                                    <Button type="primary" disabled={checkSelectedRow()} onClick={() => showModalMessage()}>
                                        <MessageOutlined />
                                        Envoyer message
                                    </Button>
                                    {isListMessages && (
                                        <ButtonResendStyled type="primary" disabled={checkSelectedRow()} onClick={() => onSubmit()}>
                                            <Icon component={() => IconComponent(resend)} />
                                            Renvoyer message
                                        </ButtonResendStyled>
                                    )}
                                </Space>
                            </FormItemStyled>
                        </Row>
                    </ColHeaderActionStyled>
                </RowHeaderActionStyled>
            </Form>
        </div>
    );
};

export default HeaderActionDashboard;
